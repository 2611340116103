// eslint-disable
// this is an auto generated file. This will be overwritten

export const createBlog = `mutation CreateBlog($input: CreateBlogInput!) {
  createBlog(input: $input) {
    id
    name
    posts {
      items {
        id
        title
        subtitle
        content
        kudos
      }
      nextToken
    }
  }
}
`;
export const updateBlog = `mutation UpdateBlog($input: UpdateBlogInput!) {
  updateBlog(input: $input) {
    id
    name
    posts {
      items {
        id
        title
        subtitle
        content
        kudos
      }
      nextToken
    }
  }
}
`;
export const deleteBlog = `mutation DeleteBlog($input: DeleteBlogInput!) {
  deleteBlog(input: $input) {
    id
    name
    posts {
      items {
        id
        title
        subtitle
        content
        kudos
      }
      nextToken
    }
  }
}
`;
export const createPost = `mutation CreatePost($input: CreatePostInput!) {
  createPost(input: $input) {
    id
    title
    subtitle
    content
    kudos
    blog {
      id
      name
    }
    comments {
      items {
        id
        content
      }
      nextToken
    }
  }
}
`;
export const updatePost = `mutation UpdatePost($input: UpdatePostInput!) {
  updatePost(input: $input) {
    id
    title
    subtitle
    content
    kudos
    blog {
      id
      name
    }
    comments {
      items {
        id
        content
      }
      nextToken
    }
  }
}
`;
export const deletePost = `mutation DeletePost($input: DeletePostInput!) {
  deletePost(input: $input) {
    id
    title
    subtitle
    content
    kudos
    blog {
      id
      name
    }
    comments {
      items {
        id
        content
      }
      nextToken
    }
  }
}
`;
export const createComment = `mutation CreateComment($input: CreateCommentInput!) {
  createComment(input: $input) {
    id
    content
    post {
      id
      title
      subtitle
      content
      kudos
    }
  }
}
`;
export const updateComment = `mutation UpdateComment($input: UpdateCommentInput!) {
  updateComment(input: $input) {
    id
    content
    post {
      id
      title
      subtitle
      content
      kudos
    }
  }
}
`;
export const deleteComment = `mutation DeleteComment($input: DeleteCommentInput!) {
  deleteComment(input: $input) {
    id
    content
    post {
      id
      title
      subtitle
      content
      kudos
    }
  }
}
`;
export const createVideoWall = `mutation CreateVideoWall($input: CreateVideoWallInput!) {
  createVideoWall(input: $input) {
    id
    shortname
    camera_id
    name
    contributors
    videos {
      items {
        id
        uuid
        uploader
      }
      nextToken
    }
  }
}
`;
export const updateVideoWall = `mutation UpdateVideoWall($input: UpdateVideoWallInput!) {
  updateVideoWall(input: $input) {
    id
    shortname
    camera_id
    name
    contributors
    videos {
      items {
        id
        uuid
        uploader
      }
      nextToken
    }
  }
}
`;
export const deleteVideoWall = `mutation DeleteVideoWall($input: DeleteVideoWallInput!) {
  deleteVideoWall(input: $input) {
    id
    shortname
    camera_id
    name
    contributors
    videos {
      items {
        id
        uuid
        uploader
      }
      nextToken
    }
  }
}
`;
export const createVideo = `mutation CreateVideo($input: CreateVideoInput!) {
  createVideo(input: $input) {
    id
    uuid
    uploader
    videowall {
      id
      shortname
      camera_id
      name
      contributors
    }
  }
}
`;
export const updateVideo = `mutation UpdateVideo($input: UpdateVideoInput!) {
  updateVideo(input: $input) {
    id
    uuid
    uploader
    videowall {
      id
      shortname
      camera_id
      name
      contributors
    }
  }
}
`;
export const deleteVideo = `mutation DeleteVideo($input: DeleteVideoInput!) {
  deleteVideo(input: $input) {
    id
    uuid
    uploader
    videowall {
      id
      shortname
      camera_id
      name
      contributors
    }
  }
}
`;
