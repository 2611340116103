// eslint-disable
// this is an auto generated file. This will be overwritten

export const getBlog = `query GetBlog($id: ID!) {
  getBlog(id: $id) {
    id
    name
    posts {
      items {
        id
        title
        subtitle
        content
        kudos
      }
      nextToken
    }
  }
}
`;
export const listBlogs = `query ListBlogs(
  $filter: ModelBlogFilterInput
  $limit: Int
  $nextToken: String
) {
  listBlogs(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      posts {
        items {
          id
          title
          subtitle
          content
          kudos
        }
        nextToken
      }
    }
    nextToken
  }
}
`;
export const getPost = `query GetPost($id: ID!) {
  getPost(id: $id) {
    id
    title
    subtitle
    content
    kudos
    blog {
      id
      name
    }
    comments {
      items {
        id
        content
      }
      nextToken
    }
  }
}
`;
export const listPosts = `query ListPosts(
  $filter: ModelPostFilterInput
  $limit: Int
  $nextToken: String
) {
  listPosts(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      title
      subtitle
      content
      kudos
      blog {
        id
        name
      }
      comments {
        items {
          id
          content
        }
        nextToken
      }
    }
    nextToken
  }
}
`;
export const getComment = `query GetComment($id: ID!) {
  getComment(id: $id) {
    id
    content
    post {
      id
      title
      subtitle
      content
      kudos
    }
  }
}
`;
export const listComments = `query ListComments(
  $filter: ModelCommentFilterInput
  $limit: Int
  $nextToken: String
) {
  listComments(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      content
      post {
        id
        title
        subtitle
        content
        kudos
      }
    }
    nextToken
  }
}
`;
export const getVideoWall = `query GetVideoWall($id: ID!) {
  getVideoWall(id: $id) {
    id
    shortname
    camera_id
    name
    contributors
    videos {
      items {
        id
        uuid
        uploader
      }
      nextToken
    }
  }
}
`;
export const listVideoWalls = `query ListVideoWalls(
  $filter: ModelVideoWallFilterInput
  $limit: Int
  $nextToken: String
) {
  listVideoWalls(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      shortname
      camera_id
      name
      contributors
      videos {
        items {
          id
          uuid
          uploader
        }
        nextToken
      }
    }
    nextToken
  }
}
`;
export const getVideo = `query GetVideo($id: ID!) {
  getVideo(id: $id) {
    id
    uuid
    uploader
    videowall {
      id
      shortname
      camera_id
      name
      contributors
    }
  }
}
`;
export const listVideos = `query ListVideos(
  $filter: ModelVideoFilterInput
  $limit: Int
  $nextToken: String
) {
  listVideos(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      uuid
      uploader
      videowall {
        id
        shortname
        camera_id
        name
        contributors
      }
    }
    nextToken
  }
}
`;
export const searchPosts = `query SearchPosts(
  $filter: SearchablePostFilterInput
  $sort: SearchablePostSortInput
  $limit: Int
  $nextToken: Int
) {
  searchPosts(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      title
      subtitle
      content
      kudos
      blog {
        id
        name
      }
      comments {
        items {
          id
          content
        }
        nextToken
      }
    }
    nextToken
  }
}
`;
