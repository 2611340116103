import React, {
  Component
} from 'react'

export default class CameraComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      cameraId: Math.random().toString(36).substr(2, 8)
    }
  }

  componentDidMount() {
    const cameraId = this.state.cameraId
    const CameraTag = window.CameraTag
    CameraTag.setup()
    // Progress Updates
    CameraTag.observe(cameraId, 'initialized', () => {
      this.setupCamera()
    })
  }

  setupCamera = () => {
    const cameraId = this.state.cameraId
    const CameraTag = window.CameraTag
    const camera = CameraTag.cameras[cameraId]
    if (camera.stack() === 'Flash') {
      this.setState({
        flashCamera: true
      })
    }
    // Progress Updates
    CameraTag.observe(cameraId, 'readyToRecord', () => {
      if (this.state.flashCamera) {
        window.alert('Sorry! Your browser is incompatible with our webcam widget. Please try Google Chrome or Mozilla Firefox.')
      }

    })

    CameraTag.observe(cameraId, 'uploadFileSelected', file => {
      this.setState({
        usingFileUpload: true
      })
    })

    CameraTag.observe(cameraId, 'processed', () => {
      const video = camera.getVideo()
      this.setState({
        processed: true,
        videoUUID: video.uuid
      })
      this.props.onComplete(video)
    })

    // Failure Handling
    CameraTag.observe(cameraId, 'noMic', () => {})
    CameraTag.observe(cameraId, 'uploadAborted', {})
    CameraTag.observe(cameraId, 'publishFailed', () => {})
    CameraTag.observe(cameraId, 'processingFailed', () => {})
  }

  render() {
    let cameraName = this.state.cameraId
    let customStartScreen = (
      <div id={`${cameraName}-start-screen`} className="cameratag_screen cameratag_start" style={{ display: 'none' }}>
        <i className="cameratag_settings_btn fa fa-cog" src="//cameratag.com/assets/gear.png"></i>
        <a className="cameratag_select_prompt">Pitch me something.</a>
        <div className="cameratag_primary_link_box">
          <a className={`cameratag_primary_link cameratag_record_link cameratag_record`}>
            <span className="cameratag_action_icon"><i className="fa fa-video-camera"></i></span>
            <br />
            <span className="cameratag_prompt_label">webcam</span>
          </a>
          <a className="cameratag_primary_link cameratag_upload_link cameratag_upload">
            <span className="cameratag_action_icon"></span><br />
            <span className="cameratag_prompt_label">upload</span>
          </a>
          <a className="cameratag_primary_link cameratag_sms_link cameratag_sms">
            <span className="cameratag_action_icon"></span><br/>
            <span className="cameratag_prompt_label">phone</span>
          </a>
        </div>
      </div>
    )

    return (
      <div style={{ zIndex: '1', width: '100%' }}>
        <camera
          id={this.state.cameraId}
          data-uuid={'a-32470de0-d19b-0136-a320-06a97369f4a4'}
          data-minlength="2"
          data-pre-roll-length="5"
          data-skip-auto-detect="true"
          data-simple-security="true"
          data-enforce-mobile-length="false"
          data-poll-for-processed="true"
        />
        {customStartScreen}
      </div>
    )
  }
}
