import React from 'react'
import { Form, Field, FormSpy } from 'react-final-form'
import createDecorator from 'final-form-focus'
import styled, {
  css
} from 'styled-components'

const btn = (light, dark) => css`
  height: 50px;
  white-space: nowrap;
  display: inline-block;
  border-radius: 5px;
  padding: 5px 15px;
  font-size: 16px;
  color: white;
  margin-top: 15px;
  &:visited {
    color: white;
  }
  background-image: linear-gradient(${light}, ${dark});
  border: 1px solid ${dark};
  &:hover {
    background-image: linear-gradient(${light}, ${dark});
    &[disabled] {
      background-image: linear-gradient(${light}, ${dark});
    }
  }
  &:visited {
    color: black;
  }
  &[disabled] {
    opacity: 0.6;
    cursor: not-allowed;
  }
`

// const btnDefault = css`
//   ${btn('#ffffff', '#d5d5d5')} color: #555;
// `

const btnPrimary = btn('#5299e8', '#3883d8')

const StyledDiv = styled.div`
  h1 {
    text-align: center;
    color: white;
    margin-bottom: 15px;
  }
  h2 {
    text-align: center;
    color: white;
  }
  h3 {
    text-align: center;
    margin: 0;
  }
  form {
    text-align: left;
    max-width: 400px;
    border-radius: 3px;
    position: relative;
    color: white;
    margin: auto;
    & > div {
      display: flex;
      flex-flow: row nowrap;
      line-height: 2em;
      position: relative;
      padding: 8px 5px;
      border: 1px solid transparent;
      width: 90%;
      margin: auto;
      margin-bottom: 10px;
      &.active {
        border-color: turquoise;
      }
      & > label {
        width: 110px;
        min-width: 60px;
        font-size: 1em;
        line-height: 32px;
      }
      & > input,
      & > select,
      & > textarea {
        font-family: monospace, monospace;
        flex: 1;
        padding: 6px 9px;
        font-size: 1em;
        margin-left: 15px;
        border: 1px solid #ccc;
        border-radius: 3px;
        max-width: 225px;
        width: 100%;
        &[disabled] {
          background: #eee;
        }
      }
      & > input[type='checkbox'] {
        margin-top: 7px;
      }
      & > div {
        margin-left: 16px;
        & > label {
          margin-left: 0;
          display: block;
          & > input {
            margin-right: 3px;
          }
        }
      }
      & > span {
        line-height: 32px;
        margin-left: 10px;
        color: #800;
        font-weight: bold;
      }
    }
    .error {
      border: 2px solid #3883d8;
      border-radius: 5px;
    }
    pre {
      position: relative;
      border: 1px solid #ccc;
      background: rgba(0, 0, 0, 0.1);
      box-shadow: inset 1px 1px 3px rgba(0, 0, 0, 0.2);
      padding: 20px;
    }
  }
  button {
    margin: 10px auto;
    &[type='submit'] {
      ${btnPrimary};
      display: block;
      width: 150px;
    }
  }
`

const focusOnError = createDecorator()
const required = value => (value ? undefined : 'Required')
const emailRequired = value => (value && /\S+@\S+\.\S+/.test(value) ? undefined : 'Required')
const FormComponent = (props) => (
  <StyledDiv>
    <h1>Get to know me.</h1>
    <Form
      onSubmit={(values, form, callback) => {
        props.onSubmit(values)
        form.reset()
      }}
      decorators={[focusOnError]}
      subscription={{
        submitted: true
      }}
    >
      {({ handleSubmit, submitting, values }) => (
        <form onSubmit={handleSubmit}>
          <Field
            name="name"
            placeholder="(or alias)"
            validate={required}
            subscription={{
              value: true,
              active: true,
              error: true,
              touched: true
            }}
          >
            {({ input, meta, placeholder }) => (
              <div className={meta.error && meta.touched ? 'error' : meta.active ? 'active' : ''}>
                <label>Name</label>
                <input {...input} placeholder={placeholder} />
              </div>
            )}
          </Field>
          <Field
            name="email"
            placeholder="embarrassing@aim.com"
            validate={emailRequired}
            subscription={{
              value: true,
              active: true,
              error: true,
              touched: true
            }}
          >
            {({ input, meta, placeholder }) => (
              <div className={meta.error && meta.touched ? 'error' : meta.active ? 'active' : ''}>
                <label>Email</label>
                <input {...input} placeholder={placeholder} />
              </div>
            )}
          </Field>
          <Field
            name="Note"
            placeholder="Info"
            validate={required}
            subscription={{
              value: true,
              active: true,
              error: true,
              touched: true
            }}
          >
            {({ input, meta, placeholder }) => (
              <div className={meta.error && meta.touched ? 'error' : meta.active ? 'active' : ''}>
                <label>Important</label>
                <textarea {...input} placeholder={placeholder} />
              </div>
            )}
          </Field>
          <FormSpy subscription={{ touched: true, hasValidationErrors: true }}>
            {({ touched, hasValidationErrors }) =>
            <button type="submit" disabled={submitting}>
              {Object.values(touched).filter(value => value === true).length > 0 && hasValidationErrors ? 'Almost...' : 'Send'}
            </button>
          }
          </FormSpy>
        </form>
      )}
    </Form>
  </StyledDiv>
)

export default FormComponent
