import './App.scss'

import React, {
  Component
} from 'react'

import FormComponent from './components/FormComponent'
import CameraComponent from './components/CameraComponent'
import {
  Controller,
  Scene
} from 'react-scrollmagic';
import {
  Tween,
  Timeline,
} from 'react-gsap';
import smoothscroll from 'smoothscroll-polyfill';
import ReactFullpage from '@fullpage/react-fullpage';
import Modal from 'react-responsive-modal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowCircleUp, faArrowCircleDown } from '@fortawesome/free-solid-svg-icons'

import Amplify, { Hub, Auth, API, Analytics, graphqlOperation } from "aws-amplify";
import {Authenticator} from 'aws-amplify-react'
import * as queries from './graphql/queries';
import * as mutations from './graphql/mutations';
import * as subscriptions from './graphql/subscriptions';
import config from "./aws-exports";
// window.LOG_LEVEL = 'DEBUG';
const analyticsConfig = {
  AWSPinpoint: {
    // Amazon Pinpoint App Client ID
    appId: '28c3e4748e8540cf9794e31f9b276a6a',
    // Amazon service region
    region: 'us-east-1',
    mandatorySignIn: false,
  }
}

const anchors = ["firstPage", "secondPage", "thirdPage"]
const colors = ["#38ced7", "#3883d8", "#38ced7"]


class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      currentIndex: 0,
      user: {},
      creds: {},
      videos: {items:[]}
    }
  }

  componentDidMount () {
    smoothscroll.polyfill()
    Hub.listen("auth", this, "Auth");
    Hub.listen("analytics", this, "Analytics");

    Amplify.configure(config)
    Analytics.configure(analyticsConfig)
  }
  onHubCapsule(capsule) {
    const { channel, payload } = capsule;
    if (channel === "auth") {
      Auth.currentCredentials().then(creds => {
        this.setState({ creds })
      })
      Auth.currentAuthenticatedUser()
        .then(user => {
          this.setState({user})
          this.fetchVideos()
        })
        .catch(err => this.setState({user: null}));
      }
  }

  fetchVideos = async () => {
    try {
      let videos = await API.graphql(graphqlOperation(queries.listVideos))
      // Subscribe to creation of Todo
      this.subscription = API.graphql(
        graphqlOperation(subscriptions.onCreateVideo)
      ).subscribe({
        next: (videoData) => {
          this.setState({ videos: {
            ...this.state.videos,
            items: [...this.state.videos.items, videoData.value.data.onCreateVideo]
          }})
        }
      });
    } catch(error) {
      console.log(error)
    }
  }
  render() {
    const options = ['video', 'humanitarianism', 'anything']
    let navScene = (
      <Scene
        duration="200"
        offset={'120%'}
        triggerHook="onLeave"
      >
        <Timeline>
          <Tween
            from={{ opacity: 1, top: '15px' }}
            to={{ opacity: 0, top: '0px' }}
          >
            <div style={{
              position: 'absolute',
              zIndex: '11',
              top: '0',
              left: '0',
              right: '0',
              width: '90%',
              margin: '0 auto',
              height: '50px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center' }}>

            </div>
          </Tween>
        </Timeline>
      </Scene>
    )
    let authModal = (
      <Modal
        open={this.state.showingAuthModal}
        onClose={() => this.setState({ showingAuthModal: false })}
        >
        <Authenticator
          // Fired when Authentication State changes
          onStateChange={(authState) => {
            if (authState === 'signedIn' ) {
              Auth.currentAuthenticatedUser()
                .then(user => {
                  this.setState({user})
                })
                .catch(err => this.setState({user: null}));
              this.setState({ showingAuthModal: false })
            }
          }}
          // hide={
          //     [
          //         Greetings,
          //     ]
          // }
          // or hide all the default components
          hideDefault={false}
          // Pass in an aws-exports configuration
          amplifyConfig={config}
        />
      </Modal>
    )

    let signin = (
      <div>
        {this.state.user ? (
          <button style={{ position: 'absolute', right: '15px', top: '15px'}} onClick={() => {
            Auth.signOut()
            this.setState({ user: null })
          }}>
            Signed in as {this.state.user.username}. Sign out?
          </button>
        ) : (
          <button style={{ position: 'absolute', right: '15px', top: '15px'}} onClick={() => this.setState({ showingAuthModal: true })}>
            {'Sign in with AWS'}
          </button>
        )}
      </div>
    )
    return (
      <div className="App">
        {authModal}
        <Controller>
          {navScene}
          <ReactFullpage
            licenseKey="OPEN-SOURCE-GPLV3-LICENSE"
            navigation
            sectionsColor={colors}
            render={({ state, fullpageApi }) => {
              return (
                <ReactFullpage.Wrapper>
                  <section className="section">
                    <Timeline>
                      <Tween
                        stagger={2.4}
                        staggerFrom={{ opacity: '0', scale: '1.25' }}
                        duration={3}
                      >
                        <div className="text text__heading">
                          Hi! <span className="text__grow">I'm Warren McQuinn</span>.
                        </div>
                        <div className="text text__quiet">
                          <Tween from={{ fontSize: '36' }} delay={3}>
                            <div>
                              I built <b>Montage</b>.
                            </div>
                          </Tween>
                        </div>
                      </Tween>
                      <Tween from={{ opacity: 0 }}>
                        <div className="image">
                          <a href="https://getmontage.co" rel="noopener noreferrer" target="_blank">
                            <img alt="Montage: Group Video Gifts" src="logo-icon-w.svg" />
                          </a>
                        </div>
                      </Tween>
                    </Timeline>
                    <Timeline>
                      <Tween
                        from={{ opacity: 0, bottom: '30px' }}
                        to={{ opacity: 1, bottom: '50px' }}
                        delay={4}
                        duration={2}
                      >
                        <div style={{
                          position: 'absolute',
                          zIndex: '11',
                          bottom: '50px',
                          right: '5%',
                          color: 'white',
                          cursor: 'pointer'
                        }}
                          onClick={() => {
                            fullpageApi.moveSectionDown()
                          }}>
                          <FontAwesomeIcon size="3x" icon={faArrowCircleDown} />
                        </div>
                      </Tween>
                    </Timeline>
                  </section>
                  <section className="section">
                    {/* <div>
                      {this.state.videos.items.map(video => <div>{video.uuid}</div>)}
                    </div> */}
                    <CameraComponent onComplete={async video => {
                      let videoDetails = {
                        uuid: video.uuid,
                        uploader: this.state.user.username || 'not_logged_in',
                        videoVideowallId: 1
                      }
                      let result = await API.graphql(graphqlOperation(mutations.createVideo, {input: videoDetails }))
                      const response = await API.post('lambda', '/simpleemail', {body: {values: videoDetails}})
                    }} />
                    <Timeline>
                      <Tween
                        from={{ opacity: 0, bottom: '30px' }}
                        to={{ opacity: 1, bottom: '50px' }}
                        >
                          <div style={{
                            position: 'absolute',
                            zIndex: '11',
                            bottom: '50px',
                            right: '5%',
                            color: 'white',
                            cursor: 'pointer'
                          }}
                          onClick={() => {
                            fullpageApi.moveSectionDown()
                          }}>
                          <FontAwesomeIcon size="3x" icon={faArrowCircleDown} />
                        </div>
                      </Tween>
                    </Timeline>
                  </section>
                  <section className="section">
                    <FormComponent onSubmit={async values => {
                      Analytics.updateEndpoint({
                        optOut: 'ALL',
                        channelType: 'EMAIL',
                        userId: values.email
                      });
                      const response = await API.post('lambda', '/simpleemail', {body: {values: values}})
                      Analytics.record({
                        name: 'contactFormFilled',
                        attributes: values
                      });
                      window.alert(`Nice to meet you, ${values.name}! We'll be in touch. 👍`)
                    }}/>
                  </section>
                  <Scene
                    offset={'-150%'}
                    duration="200"
                    triggerHook="onEnter"
                  >
                    <div style={{ position: 'relative' }}>
                      <Timeline>
                        <Tween
                          from={{ opacity: 0, bottom: '30px' }}
                          to={{ opacity: 1, bottom: '50px' }}
                          >
                            <div style={{
                              position: 'absolute',
                              zIndex: '11',
                              bottom: '50px',
                              right: '5%',
                              color: 'white',
                              cursor: 'pointer'
                            }}
                            onClick={() => {
                              fullpageApi.moveTo(1)
                            }}>
                            <FontAwesomeIcon size="3x" icon={faArrowCircleUp} />
                          </div>
                        </Tween>
                      </Timeline>
                    </div>
                  </Scene>
                </ReactFullpage.Wrapper>
              );
            }}
          />
        </Controller>
    </div>
    );
  }
}

export default App;
