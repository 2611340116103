// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile =  {
    "aws_project_region": "us-east-1",
    "aws_content_delivery_bucket": "mcquinn.us",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "https://d26xcyyeg04ii7.cloudfront.net",
    "aws_appsync_graphqlEndpoint": "https://pfkkr6u57re33di5uxcpaajt5a.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cloud_logic_custom": [
        {
            "name": "lambda",
            "endpoint": "https://8esdrn51tg.execute-api.us-east-1.amazonaws.com/Prod",
            "region": "us-east-1"
        }
    ],
    "aws_cognito_identity_pool_id": "us-east-1:1abd942c-0bc1-445e-b45b-f66ca941af3f",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_nHXGTDvVZ",
    "aws_user_pools_web_client_id": "2tb0gj4820i07s7r05uslrbe5r",
    "aws_mobile_analytics_app_id": "28c3e4748e8540cf9794e31f9b276a6a",
    "aws_mobile_analytics_app_region": "us-east-1"
};


export default awsmobile;
