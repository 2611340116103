// eslint-disable
// this is an auto generated file. This will be overwritten

export const onCreateBlog = `subscription OnCreateBlog {
  onCreateBlog {
    id
    name
    posts {
      items {
        id
        title
        subtitle
        content
        kudos
      }
      nextToken
    }
  }
}
`;
export const onUpdateBlog = `subscription OnUpdateBlog {
  onUpdateBlog {
    id
    name
    posts {
      items {
        id
        title
        subtitle
        content
        kudos
      }
      nextToken
    }
  }
}
`;
export const onDeleteBlog = `subscription OnDeleteBlog {
  onDeleteBlog {
    id
    name
    posts {
      items {
        id
        title
        subtitle
        content
        kudos
      }
      nextToken
    }
  }
}
`;
export const onCreatePost = `subscription OnCreatePost {
  onCreatePost {
    id
    title
    subtitle
    content
    kudos
    blog {
      id
      name
    }
    comments {
      items {
        id
        content
      }
      nextToken
    }
  }
}
`;
export const onUpdatePost = `subscription OnUpdatePost {
  onUpdatePost {
    id
    title
    subtitle
    content
    kudos
    blog {
      id
      name
    }
    comments {
      items {
        id
        content
      }
      nextToken
    }
  }
}
`;
export const onDeletePost = `subscription OnDeletePost {
  onDeletePost {
    id
    title
    subtitle
    content
    kudos
    blog {
      id
      name
    }
    comments {
      items {
        id
        content
      }
      nextToken
    }
  }
}
`;
export const onCreateComment = `subscription OnCreateComment {
  onCreateComment {
    id
    content
    post {
      id
      title
      subtitle
      content
      kudos
    }
  }
}
`;
export const onUpdateComment = `subscription OnUpdateComment {
  onUpdateComment {
    id
    content
    post {
      id
      title
      subtitle
      content
      kudos
    }
  }
}
`;
export const onDeleteComment = `subscription OnDeleteComment {
  onDeleteComment {
    id
    content
    post {
      id
      title
      subtitle
      content
      kudos
    }
  }
}
`;
export const onCreateVideoWall = `subscription OnCreateVideoWall {
  onCreateVideoWall {
    id
    shortname
    camera_id
    name
    contributors
    videos {
      items {
        id
        uuid
        uploader
      }
      nextToken
    }
  }
}
`;
export const onUpdateVideoWall = `subscription OnUpdateVideoWall {
  onUpdateVideoWall {
    id
    shortname
    camera_id
    name
    contributors
    videos {
      items {
        id
        uuid
        uploader
      }
      nextToken
    }
  }
}
`;
export const onDeleteVideoWall = `subscription OnDeleteVideoWall {
  onDeleteVideoWall {
    id
    shortname
    camera_id
    name
    contributors
    videos {
      items {
        id
        uuid
        uploader
      }
      nextToken
    }
  }
}
`;
export const onCreateVideo = `subscription OnCreateVideo {
  onCreateVideo {
    id
    uuid
    uploader
    videowall {
      id
      shortname
      camera_id
      name
      contributors
    }
  }
}
`;
export const onUpdateVideo = `subscription OnUpdateVideo {
  onUpdateVideo {
    id
    uuid
    uploader
    videowall {
      id
      shortname
      camera_id
      name
      contributors
    }
  }
}
`;
export const onDeleteVideo = `subscription OnDeleteVideo {
  onDeleteVideo {
    id
    uuid
    uploader
    videowall {
      id
      shortname
      camera_id
      name
      contributors
    }
  }
}
`;
